var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page inner-container collection-detail"},[_c('section',{staticClass:"collection--detail--row collection--back"},[_c('button',{on:{"click":_vm.backToCollectionList}},[_vm._v(" "+_vm._s(_vm.$t("collection.collection_back"))+" ")])]),_c('section',{staticClass:"collection--detail--row collection--description"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.collection.title_en))]),_c('div',{staticClass:"editField",on:{"click":function($event){_vm.openEditCollectionModal(
                  'title_en',
                  _vm.$t('collection.collection_title_en'),
                  _vm.collection.title_en
                )}}},[_vm._v(" "+_vm._s(_vm.$t("collection.collection_edit_button"))+" ")])])]),_c('div',{staticClass:"col-12 col-md-6 "},[_c('p',[_vm._v(" "+_vm._s(_vm.collection.description_en)+" ")]),_c('div',{staticClass:"editField",on:{"click":function($event){_vm.openEditCollectionModal(
                'description_en',
                _vm.$t('collection.collection_description_en'),
                _vm.collection.description_en
              )}}},[_vm._v(" "+_vm._s(_vm.$t("collection.collection_edit_button"))+" ")])])]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.collection.title_tc))]),_c('div',{staticClass:"editField",on:{"click":function($event){_vm.openEditCollectionModal(
                  'title_tc',
                  _vm.$t('collection.collection_title_tc'),
                  _vm.collection.title_tc
                )}}},[_vm._v(" "+_vm._s(_vm.$t("collection.collection_edit_button"))+" ")])])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('p',[_vm._v(" "+_vm._s(_vm.collection.description_tc)+" ")]),_c('div',{staticClass:"editField",on:{"click":function($event){_vm.openEditCollectionModal(
                'description_tc',
                _vm.$t('collection.collection_description_tc'),
                _vm.collection.description_tc
              )}}},[_vm._v(" "+_vm._s(_vm.$t("collection.collection_edit_button"))+" ")])])])]),_c('section',{staticClass:"collection--detail--row collection--single--list"},[_c('div',{directives:[{name:"masonry",rawName:"v-masonry"}],attrs:{"transition-duration":"0","item-selector":".item","column-width":".item__sizer","percent-position":"true","gutter":".item__gutter"}},[_c('div',{staticClass:"item__sizer"}),_c('div',{staticClass:"item__gutter"}),_c('transition-group',{attrs:{"name":"list"}},_vm._l((_vm.collection.records),function(record,index){return _c('div',{directives:[{name:"masonry-tile",rawName:"v-masonry-tile"}],key:record.id},[_c('SingleItem',{attrs:{"name":record.title_en,"imageName":record.gallery[0].image,"hoverFunction":false,"relatedItem":false,"removeSingleItemId":record.id,"hoverColor":_vm.getSingleItemHoverColor},on:{"removeSingleRecord":function($event){return _vm.removeRecordHandler(record.id)},"openRecordDetial":function($event){return _vm.openDetailModal(index, record.slug)}}})],1)}),0)],1)])]),_c('CollectionDetailModal',{ref:"collectionModal",attrs:{"collections":_vm.collection,"collectionDetailOpened":_vm.openDetail,"collectionThumbsIndex":_vm.thumbsIndex},on:{"collectionDetailClosed":_vm.closeDetail}}),_c('editCollectionModal',{attrs:{"editCollectionOpened":_vm.editCollectionModalOpened,"editCollectionType":_vm.editCollectionType,"editCollectionTypeLabel":_vm.editCollectionTypeLabel,"editCollectionOrigValue":_vm.editCollectionOrigValue},on:{"closeEditCollectionModal":_vm.handleCloseEditCollectionModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }