<template>
  <div>
    <div class="page inner-container collection-detail">
      <section class="collection--detail--row collection--back">
        <button @click="backToCollectionList">
          {{ $t("collection.collection_back") }}
        </button>
      </section>

      <section class="collection--detail--row collection--description">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="title">
              <h2>{{ collection.title_en }}</h2>
              <div
                class="editField"
                @click="
                  openEditCollectionModal(
                    'title_en',
                    $t('collection.collection_title_en'),
                    collection.title_en
                  )
                "
              >
                {{ $t("collection.collection_edit_button") }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 ">
            <p>
              {{ collection.description_en }}
            </p>
            <div
              class="editField"
              @click="
                openEditCollectionModal(
                  'description_en',
                  $t('collection.collection_description_en'),
                  collection.description_en
                )
              "
            >
              {{ $t("collection.collection_edit_button") }}
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 col-md-6">
            <div class="title">
              <h2>{{ collection.title_tc }}</h2>
              <div
                class="editField"
                @click="
                  openEditCollectionModal(
                    'title_tc',
                    $t('collection.collection_title_tc'),
                    collection.title_tc
                  )
                "
              >
                {{ $t("collection.collection_edit_button") }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <p>
              {{ collection.description_tc }}
            </p>
            <div
              class="editField"
              @click="
                openEditCollectionModal(
                  'description_tc',
                  $t('collection.collection_description_tc'),
                  collection.description_tc
                )
              "
            >
              {{ $t("collection.collection_edit_button") }}
            </div>
          </div>
        </div>
      </section>
      <section class="collection--detail--row collection--single--list">
        <div
          v-masonry=""
          transition-duration="0"
          item-selector=".item"
          column-width=".item__sizer"
          percent-position="true"
          gutter=".item__gutter"
        >
          <div class="item__sizer"></div>
          <div class="item__gutter"></div>
          <transition-group name="list">
            <div
              v-masonry-tile
              v-for="(record, index) in collection.records"
              :key="record.id"
            >
              <SingleItem
                :name="record.title_en"
                :imageName="record.gallery[0].image"
                :hoverFunction="false"
                :relatedItem="false"
                :removeSingleItemId="record.id"
                :hoverColor="getSingleItemHoverColor"
                @removeSingleRecord="removeRecordHandler(record.id)"
                @openRecordDetial="openDetailModal(index, record.slug)"
              />
            </div>
          </transition-group>
        </div>
      </section>
    </div>
    <CollectionDetailModal
      ref="collectionModal"
      :collections="collection"
      :collectionDetailOpened="openDetail"
      @collectionDetailClosed="closeDetail"
      :collectionThumbsIndex="thumbsIndex"
    />
    <editCollectionModal
      :editCollectionOpened="editCollectionModalOpened"
      :editCollectionType="editCollectionType"
      :editCollectionTypeLabel="editCollectionTypeLabel"
      :editCollectionOrigValue="editCollectionOrigValue"
      @closeEditCollectionModal="handleCloseEditCollectionModal"
    ></editCollectionModal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { authHeader } from "@/helpers/authHeader";
import SingleItem from "@/components/masonry/SingleItem";
import editCollectionModal from "@/components/collection/editCollectionModal";
import CollectionDetailModal from "@/components/common/CollectionDetailModal";
export default {
  name: "CollectionDetailEdit",
  components: { SingleItem, CollectionDetailModal, editCollectionModal },
  data() {
    return {
      collection: {},
      openDetail: false,
      thumbsIndex: 0,
      collectionId: this.$route.params.id,
      editCollectionModalOpened: false,
      editCollectionType: "",
      editCollectionTypeLabel: "",
      editCollectionOrigValue: ""
    };
  },

  beforeMount() {
    this.apiCollectonDetail();
    setTimeout(() => {
      this.$redrawVueMasonry();
    }, 1500);
  },
  computed: {
    ...mapGetters("common", ["getSingleItemHoverColor"])
  },
  methods: {
    ...mapActions("common", ["setPreventScrolling"]),

    openEditCollectionModal(fieldType, fieldLabel, fieldOrigValue) {
      this.editCollectionType = fieldType;
      this.editCollectionOrigValue = fieldOrigValue;
      this.editCollectionTypeLabel = fieldLabel;
      this.editCollectionModalOpened = !this.editCollectionModalOpened;
      this.setPreventScrolling(true);
    },

    handleCloseEditCollectionModal(value) {
      this.editCollectionModalOpened = value.modalState;
      this.apiCollectonDetail();
      this.setPreventScrolling(false);
    },

    openDetailModal(index, slug) {
      this.openDetail = !this.openDetail;
      this.thumbsIndex = index;
      this.$refs.collectionModal.clickedRecord(this.thumbsIndex, slug);
      this.setPreventScrolling(true);
    },

    closeDetail(value) {
      this.openDetail = value.modalState;
      this.setPreventScrolling(false);
    },

    backToCollectionList() {
      this.$router.push("/" + this.lang + "/profile");
    },
    removeRecordHandler(recordId) {
      this.apiRemoveRecordCollection(recordId, this.collectionId);
      setTimeout(() => {
        this.$redrawVueMasonry();
      }, 1500);
    },

    async apiCollectonDetail() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/collection/${this.$route.params.slug}`,
          {
            method: "GET",
            mode: "cors",
            cache: "default"
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }

        const data = await response.json();

        this.collection = data;
      } catch (error) {
        console.log(error);
      }
    },

    async apiRemoveRecordCollection(recordId, collectionId) {
      try {
        console.log(localStorage.getItem("user"));
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/collection/${collectionId}/record/${recordId}`,
          {
            method: "DELETE",
            mode: "cors",
            cache: "default",
            headers: authHeader(),
            body: null
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }
        let responseData = await response.json();

        await this.apiCollectonDetail();

        // Call parent method to get the collection list again
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  &__sizer {
    width: 48%;
    // width:calc(47% + 10px);
    // width: 224px;
    @include respond-to(screen-md) {
      // width: 15%;
      // width: 224px;
      width: 23.125%;
    }
    @include respond-to(screen-xl) {
      width: 15%;
      // width: 224px;
    }
  }
  &__gutter {
    width: 3.5%;
    @include respond-to(screen-md) {
      width: 2.5%;
      // width: 224px;
    }
    @include respond-to(screen-xl) {
      width: 2%;
      // width: 224px;
    }
  }
}

.page {
  padding-top: 20px;
  padding-bottom: 40px;
  @include respond-to(screen-md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.collection {
  &--description {
    font-family: "Graphik Compact", sans-serif;
    margin-bottom: 30px;
    h2 {
      font-size: 28px;
      font-weight: 300;
      // font-size: 44px;
      @include respond-to(screen-md) {
        font-size: 44px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 100;
      @include respond-to(screen-md) {
        font-size: 18px;
      }
    }
    .title {
      margin-bottom: 15px;
      @include respond-to(screen-md) {
        margin-bottom: 30px;
      }
    }
    .editField {
      display: inline-block;
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &--single {
    &--list {
      .single--item {
        padding-bottom: 30px;
      }
    }
  }
  &--back {
    padding-bottom: 30px;
    @include respond-to(screen-md) {
      padding-bottom: 30px;
    }
    button {
      font-family: "Graphik Compact", sans-serif;
      font-size: 16px;
      &::before {
        content: "";
        margin-right: 5px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-image: url("~@/assets/images/collection--back.svg");
        background-repeat: no-repeat;
      }
    }
  }
  &--description {
    margin-bottom: 40px;
    @include respond-to(screen-md) {
      margin-bottom: 100px;
    }
  }
}
.collection--back {
  button {
    opacity: 1;
    @include transition(all 300ms ease-in-out);
    &:hover {
      opacity: 0.5;
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
